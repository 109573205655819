import React from "react"
// import { Link } from "gatsby"
import BioImg from "../components/images/BioImg"

import Layout from "../components/LayoutComponent"
import Seo from "../components/Seo"
import styled from 'styled-components';

const StyledText = styled.article`
  margin-top: 75px;
  @media (min-width: 500px) {
    margin-top: 40px;
  }
  @media (min-width: 800px) {
    margin-top: 0px;
  }
`;

const Bio = () => (
  <Layout>
  <Seo title="Bio" />
  <BioImg style={{ position: 'relative', zIndex: 0 }}/>
  <StyledText>
      <p style={{ position: 'relative', marginTop: '-100px', zIndex: 2 }}>
        Johann was born in 1985 in Memphis, Tennessee, and raised in the small
        town of Lumby in the interior of British Columbia. His father was a
        professional bassoonist and orchestra musician, so from a very early age
        he was raised in a musical environment.
      </p>
      <p>
        After moving to Victoria, BC at the age of 12 he began learning to play
        music on the bassoon, and a few years later started on the trombone,
        both of which he has played for roughly 20 years and considers his main
        instruments. He has played in numerous bands throughout junior high and
        high school in a variety of styles including Jazz, Ska, RnB, and
        Classical.
      </p>
      <p>
        After high school he enrolled in the Victoria Conservatory of Music's
        Certificate in Music Foundations program, as a joint trombone/bassoon
        major, getting further training in the foundations of music. Graduating
        two years later with a Diploma in Jazz Studies, he decided to pursue
        higher education to start a career that would be complementary to being
        a professional musician, and enrolled in Capilano University's
        Bachelor's in Music Therapy program in North Vancouver.
      </p>
      <p>
        Graduating with a Bachelor's Degree in Music Therapy in 2018, he now
        maintains the accreditations of MTA (Music-Therapist Accredited) and
        MT-BC (Music-Therapist, Board-Certified) the two standard regulatory
        bodies for Music Therapists in Canada and the U.S. respectively.
      </p>
      <p>
        In 2013 he discovered the handpan, a beautiful and unique melodic
        percussion instrument invented in 2001, making it one of the newest
        acoustic instruments made. The handpan is the focus of his performing
        work in recent years, from busking in Vancouver to playing gigs
        including wedding and corporate events, recording an album and numerous
        videos, and more recently, teaching music lessons to handpan players.
      </p>
    </StyledText>
  </Layout>
)

export default Bio
