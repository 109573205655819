import React from 'react';
import { StaticImage } from "gatsby-plugin-image"

const BioImg = () => 
    <StaticImage
      src="../../images/bio-background.jpg"
      alt="photo of Johann Mcbee"
      placeholder="photo of Johann Mcbee"
      layout="fullWidth"
      loading="eager"
      placeholder="blurred"
    />

export default BioImg
